export default function Language({ onLanguageChange, language }) {
  const sendFormData = async (content) => {
    const formData = new FormData();
    formData.append("subject", `Someone clicked on: ${content}`);
    formData.append("access_key", "e7e127ca-a7c4-4ac3-af0e-822e6dd1172f");
    formData.append("name", "admin");
    formData.append("email", "x@x@gmail.com");
    formData.append("message", `Someone clicked on: ${content}`);
    formData.append("redirect", "https://web3forms.com/success");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
      } else {
      }
    } catch (error) {}
  };

  return (
    <section id="language" className="text-sm font-extralight">
      <a
        onClick={() => {
          onLanguageChange("EN");
          sendFormData("EN");
        }}
        className={language === "EN" ? "font-bold" : ""}
      >
        EN
      </a>

      <a
        onClick={() => {
          onLanguageChange("NL");
          sendFormData("NL");
        }}
        className={language === "NL" ? "font-bold" : ""}
      >
        NL
      </a>
      <a
        onClick={() => {
          onLanguageChange("FR");
          sendFormData("FR");
        }}
        className={language === "FR" ? "font-bold" : ""}
      >
        FR
      </a>
    </section>
  );
}
