import React from "react";
import {CVLINKS} from "./about-background";

export const main = {
    EN: (
        <div className="max-w-xl flex flex-col gap-3">
            <p className="text-2xl ">Hi, I'm Lucas Lebrun, Fullstack Java developer based in Leuven, Belgium.</p>
            <p>I develop softwares mostly in Java (Spring) and Angular but also in C++ (JUCE Framework) for very
                diverse
                projects, ranging from web applications, signal processing (audio, video, sensors), data analysis,
                to multimedia
                synchronisation.</p>
            <p>I also teach creative coding to adults and young adults.</p>
            <p>Before that I worked a few years as a sound and light technician where I started programming my own
                tools and interfaces.</p>
            <p>I'm perfectly tri-lingual in English, French and Dutch.</p>
            <p>I’m excited about the opportunity to join a team where I bring value and continue to grow.</p>
            <h3 className="text-xl text-left mt-2 mb-8">Resume: {CVLINKS} </h3>
        </div>
    ),
    FR: (
        <div className="max-w-xl flex flex-col gap-3">
            <p className="text-2xl ">Bonjour, je suis Lucas Lebrun, développeur Fullstack Java basé à Louvain, Belgique.</p>
            <p>Je développe des logiciels principalement en Java (Spring) et Angular mais aussi en C++ (JUCE Framework) pour des projets très divers, allant des applications web, traitement du signal (audio, vidéo, capteurs), analyse de données, à la synchronisation multimédia.</p>
            <p>J'enseigne également le codage créatif aux adultes et jeunes adultes.</p>
            <p>Avant cela, j'ai travaillé quelques années en tant que technicien son et lumière où j'ai commencé à programmer mes propres outils et interfaces.</p>
            <p>Je suis parfaitement trilingue en anglais, français et néerlandais.</p>
            <p>Je suis enthousiaste à l'idée de rejoindre une équipe où je peux apporter de la valeur et continuer à évoluer.</p>
            <h3 className="text-xl text-left mt-2 mb-8">Curriculum vitae: {CVLINKS} </h3>
        </div>
    ),
    NL: (
        <div className="max-w-xl flex flex-col gap-3">
            <p className="text-2xl ">Hallo, ik ben Lucas Lebrun, Fullstack Java ontwikkelaar gevestigd in Leuven, België.</p>
            <p>Ik ontwikkel software voornamelijk in Java (Spring) en Angular maar ook in C++ (JUCE Framework) voor zeer diverse projecten, variërend van webapplicaties, signaalverwerking (audio, video, sensoren), data-analyse, tot multimedia synchronisatie.</p>
            <p>Ik geef ook les in creatief coderen aan volwassenen en jongvolwassenen.</p>
            <p>Daarvoor heb ik een paar jaar gewerkt als geluid- en lichttechnicus waar ik mijn eigen tools en interfaces begon te programmeren.</p>
            <p>Ik ben perfect drietalig in Engels, Frans en Nederlands.</p>
            <p>Ik ben enthousiast over de mogelijkheid om deel uit te maken van een team waar ik waarde kan toevoegen en verder kan groeien.</p>
            <h3 className="text-xl text-left mt-2 mb-8">Curriculum vitae: {CVLINKS} </h3>
        </div>
    ),
};
