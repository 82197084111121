import React, { useEffect } from "react";

export default function Welcome() {
  useEffect(() => {
    const timer = setTimeout(() => {
      const nameElement = document.getElementById("name");
      const websiteContentElement = document.getElementById("website-content");
      const app = document.getElementsByClassName("App")[0];

      nameElement.classList.add("hide");
      setTimeout(() => {
        websiteContentElement.classList.add("hide");
        setTimeout(() => {
          app.classList.add("appear");
        }, 250);
      }, 250);
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="welcome" className="text-2xl sm:text-4xl">
      <div id="name" className="font-normal">
        Lucas Lebrun
      </div>
      <div id="website-content">Portfolio</div>
    </div>
  );
}
