export const SKILLS = [
  {
    category: "Backend",
    skills: [
      "Java",
      "Spring, Spring Boot",
      "Hibernate",
      "MySQL","PostgreSQL",
      "Maven",
      "Rest APIs",
      "JUnit",
      "C++ (JUCE)",
    ],
  },
  {
    category: "Frontend",
    skills: [

      "Angular, React.js",
      "Javascript","Typescript",
      "HTML, CSS, TailwindCSS",
      "Chart.js",
      "P5.js",
      "Bootstrap",
      "Responsive Design",
      "Figma",
    ],
  },
  {
    category: "Tools",
    skills: [
      "Git",
      "GitHub",
      "Gitlab",
      "Postman",
      "IntelliJ IDEA",
      "VS Code",
      "Docker",
      "Google Cloud Platform",
      "Jira",
    ],
  },

  {
    category: "Methodologies",
    skills: ["Agile", "Scrum", "Pair Programming", "Test Driven Development"],
  },

];
