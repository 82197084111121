import React from "react";

export default function Credits() {
  const currentDate = new Date().getFullYear();

  return (
    <section id="credits" className="text-xs flex gap-0">
      <span className="hidden sm:block">© All rights reserved</span>{" "}
      <span className="block sm:hidden">©</span> // <span>{currentDate}</span>{" "}
      //
      <span id="email" className="font-bold underl">
        <a href="mailto:lucaslebrun.dev@gmail.com">
          {" "}
          lucaslebrun.dev@gmail.com
        </a>
      </span>
    </section>
  );
}
