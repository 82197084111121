import React, { useState, useEffect } from "react";

export default function Theme() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.style.setProperty(
        "--background-color",
        "var(--almost-black)"
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        "var(--almost-white)"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        "var(--light-grey)"
      );
    } else {
      document.documentElement.style.setProperty(
        "--background-color",
        "var(--almost-white)"
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        "var(--dark-grey)"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        "var(--middle-grey)"
      );
    }
  }, [darkMode]);

  return (
    <section id="theme" className="text-sm font-normal secondary-color">
      <a onClick={() => setDarkMode(false)}>LIGHT</a>
      <a onClick={() => setDarkMode(true)}>DARK</a>
    </section>
  );
}
